import { computed } from 'vue'
import { useUser } from '~/composables/auth/use-customer-info'

export const avatarUser = computed<string | undefined>(() =>
  useAvatar(useUser().value?.avatar),
)

export function useAvatar(img: string | undefined): string {
  const defaultAvatar = '/0/0.png'
  const staticPrefix = useRuntimeConfig().public.STATIC

  if (!img || img.trim().length < 1)
    img = defaultAvatar

  if (img.startsWith('http'))
    return img // TODO: replace http://host for production

  else if (img.startsWith('/static'))
    return staticPrefix + img.replace('/static', '')

  else if (img.startsWith('/'))
    return `${staticPrefix}/avatars${img}`

  else
    return `${staticPrefix}/${img}`
}

export function useOrgAvatar(img: string): string {
  const env = useRuntimeConfig()
  const orgPhotoDefaultFolder = 'org'
  if (img.startsWith('http'))
    return img

  else if (img.startsWith('/static'))
    return env.public.STATIC + img.replace('/static', '')

  return `${env.public.STATIC}/${orgPhotoDefaultFolder}/logo${img}`
}

export function logoTextSrc(): string {
  const folderLogo = `${useRuntimeConfig().public.STATIC}/img/logo/png`
  return `${folderLogo}/logo_text.png`
}
